import React, {useState} from 'react';
import {Link} from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

export default function TailwindHero() {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className="relative bg-white overflow-hidden">
            <div className="max-w-7xl mx-auto">
                <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                    <polygon points="50,0 100,0 50,100 0,100" />
                </svg>

                <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                    <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start" aria-label="Global">
                        <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                            <div className="flex items-center justify-between w-full md:w-auto">
                                <Link to="/">
                                    <span className="sr-only">Workflow</span>
                                    <div className="mt-2">
                                        <StaticImage 
                                            src="./../images/xl-logo.png"
                                            alt="Bee Logo"
                                            placeholder="blurred"
                                        />
                                    </div>
                                </Link>
                                <div className="-mr-2 flex items-center md:hidden">
                                    <button type="button" onClick={() => setIsOpen(!isOpen)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-controls="mobile-menu" aria-expanded="false">
                                        <span className="sr-only">Open main menu</span>
                                        
                                        {!isOpen && <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                                        </svg>}
                                        
                                        {isOpen && <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                            <Link to="/" activeClassName="text-yellow-400" className="font-medium text-gray-500 hover:text-gray-900">Home</Link>

                            <Link to="/about" activeClassName="text-yellow-400" className="font-medium text-gray-500 hover:text-gray-900">About</Link>

                            <Link to="/contact" activeClassName="text-yellow-400" className="font-medium text-gray-500 hover:text-gray-900">Contact</Link>

                            <a href="tel:832-344-7006" activeClassName="text-yellow-400" className="font-medium bg-yellow-400 text-gray-900 hover:bg-yellow-500 p-3 rounded-lg">(832)-344-7006</a>
                        </div>
                    </nav>
                </div>

                
                {isOpen && <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden" id="mobile-menu">
                    <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="px-5 pt-4 flex items-center justify-between">
                            <div>
                                <div className="">
                                    <StaticImage 
                                        src="./../images/xl-logo.png"
                                        alt="Bee Logo"
                                        placeholder="blurred"
                                    />
                                </div>
                            </div>
                            <div className="-mr-2">
                            <button type="button" onClick={() => setIsOpen(!isOpen)} className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                <span className="sr-only">Close main menu</span>
                                
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            </div>
                        </div>
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            <Link to="/" onClick={() => setIsOpen(!isOpen)} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-yellow-400">Home</Link>

                            <Link to="/about" onClick={() => setIsOpen(!isOpen)} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-yellow-400">About</Link>

                            <Link to="/contact" onClick={() => setIsOpen(!isOpen)} className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-yellow-400">Contact</Link>

                            <a href="tel:832-344-7006" onClick={() => setIsOpen(!isOpen)} className="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-600 hover:text-gray-900 hover:bg-yellow-400 ">Call - (832) 344-7006</a>

                            </div>
                        </div>
                    </div>}

                    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                        <div className="sm:text-center lg:text-left">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                            <span className="block">Brody's Busy Bees </span>
                            <span className="block text-yellow-400 text-3xl sm:text-4xl md:text-5xl mt-2">we got the bees</span>
                        </h1>
                        <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                            Do you have unwanted honey bees around your home or property? We are here to provide a no kill solution that will leave everyone happy, bees included.
                        </p>
                        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                            <div className="rounded-lg shadow">
                                <Link to="/contact" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-lg text-gray-900 bg-yellow-400 hover:bg-yellow-500 md:py-4 md:text-lg md:px-10">
                                    Get in touch
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
                </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0">
                <div className="">
                    <StaticImage 
                        alt="Bee hive"
                        src="./../images/hero1.jpg"
                        placeholder="blurred" 
                    />
                </div>
            </div>
        </div>
    )
}
